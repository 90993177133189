import smartPhoto from "smartphoto";
import "smartphoto/css/smartphoto.min.css";

// Glide
// Required Core Stylesheet
import "@glidejs/glide/src/assets/sass/glide.core.scss";
// Optional Theme Stylesheet
import "@glidejs/glide/src/assets/sass/glide.theme.scss";

import Glide from '@glidejs/glide';

window.addEventListener('DOMContentLoaded', () => {
    /*
    if (document.querySelector('#homepage-slider').querySelector('.glide__slide') !== null) {
        const glideCarousel = new Glide('#homepage-slider', {
            type: 'carousel',
            perView: 1,
            gap: 0,
        });
        glideCarousel.mount();
    }

     */

    let imgsSmartphoto = document.getElementById('smartphoto');
    if (imgsSmartphoto){
        new smartPhoto(document.getElementsByClassName('js-smartphoto'));
    }
});
